.verify-user {
    margin-top: 60px;
    margin-bottom: 40px;

    h4 {
        margin-bottom: 2rem;
    }

    col {
        align-items: center;
    }

    input {
        text-align: center;
        font-size: 1.5rem;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
    }

    .resend-btn {
        background-color: var(--white);
        border: none;
        box-shadow: none;
        color: var(--gray);
        padding: 0px 5px 5px 5px;
        text-decoration-line: underline;
        text-decoration-style: dotted;

        &:hover {
            box-shadow: none;
        }

        &:active {
            background-color: var(--white);
            border-color: var(--white);
            color: black;
        }
    }
}
