
.back-nav {
    color: var(--dark);
    margin-left: 15px;
    padding-top: 20px;
  }

  #link {
    color: var(--gray);
    display: flex;
    font-size:22px;
    font-weight: 600;

    &:hover {
      color: var(--dark);
      font-weight: 600;
      text-decoration: underline;
      vertical-align: middle;
    }

    .span {
      font-size: 18px;
      vertical-align: middle;
    }
  }


  @media screen and (max-width: 425px) {
    .back-nav {
      margin-left: 5px;
      padding-top: 10px;
    }

    a {
      font-size:18px;
    }
}
