.reset-password {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

  .vl {
    border-left: 2px solid var(--light-gray);
    height: 10rem;
    margin: 0;
    font-size: 0.6rem;
    margin-left: 2rem;
    padding-left: 1.5rem;
  }
}

.reset-password-text {
  b {
    line-break: anywhere;
  }
}

.custom-border {
  border-radius: 0.5rem;
  box-shadow: 0 0 10px lightgrey;
  margin-bottom: 5vh;
  margin-top: 10vh;
  max-width: 80%;

  .form-display {
    min-height: 75vh;
  }

  .gradient-custom-left {
    /* fallback for old browsers */
    background: rgb(2, 0, 36);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(2, 47, 65, 0.9332983193277311) 35%,
      rgba(3, 25, 38, 0.9304971988795518) 100%
    );
    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(2, 47, 65, 0.9332983193277311) 35%,
      rgba(3, 25, 38, 0.9304971988795518) 100%
    );

    align-items: center;
    display: flex;
    justify-content: center;
    user-select: none;
  }
}

@media (min-width: 300px) and (max-width: 400px) {
  .reset-password-submit-btn {
    max-width: 200px;
  }
}
