.results {
  margin-top: 40px;
  max-width: 1000px;

  h2 {
    text-align: center;
  }

  h3 {
    margin-bottom: 1rem;
  }

  .score {
    font-size: 2rem;
    text-decoration: underline;
  }

  h4 {
    color: var(--gray);
    font-style: italic;
    margin-bottom: 2rem;
    margin-top: 2rem;
    text-align: center;
  }

  h5.submitted {
    color: var(--light-gray);
    font-size: 1.1rem;
    font-style: italic;
    margin-top: 0.3rem;
    margin-bottom: 1.5rem;
  }

  span {
    color: var(--primary);
  }

  .table-hover {
    border: var(--primary);
    vertical-align: middle;
    min-height: 190px;
    text-align: center;
  }

  .btn-primary {
    right: 0;
    text-align: center;

    a {
      color: white;
      font-size: 20px;
      font-weight: 600;
    }
  }

  p {
    font-size: 0.5rem;
  }

  .card {
    align-items: center;
    padding: 20px;
    height: 100%;
    text-align: center;
    box-shadow: 0px 1px 4px var(--light-gray);

    &:hover {
      border-color: var(--dark-primary);
      box-shadow: 0px 0px 2px rgba(251, 144, 42, 0.6) inset, 0 0 4px rgba(251, 144, 42, 0.6);
    }
  }

  .card-img {
    width: 5rem;
    position: inherit;
    align-self: center;
    margin-top: 3px;
  }

  .card-body {
    color: var(--primary);
    font-weight: 600;
    font-size: 1.2rem;
    padding-bottom: 0px;
  }

  .container {
    justify-content: space-between;
    display: flex;
    column-gap: 20px;
  }

  text {
    background-color: aquamarine;
    alignment-baseline: text-after-edge;
  }

  .alert {
    color: var(--secondary);
    background-color: var(--light-primary);
    font-size: 0.9rem;
    border-radius: 15px;
    text-align: center;
    font-weight: 700;

    span {
      font-size: 18px;
      padding-right: 5px;
      color: var(--dark);
    }
  }

  .score-section {
    border: var(--light-gray);
    border-style: solid;
    padding: 15px;
    border-radius: 20px;
    border-width: 0.11rem;
  }

  @media (max-width: 768px) {
    text-align: center;

    .text-start {
      place-content: space-evenly;
    }

    h2.quiz-title {
      text-align: center;
      // TODO: PLACEHOLDER TO CONTINUE IMPROVING UI
    }

    .alert {
      text-align: justify;
    }

    h3 {
      font-size: 1.3rem;
      margin-bottom: 0.7rem;
    }

    .card-box {
      margin-bottom: 0.5rem;
    }
  }
}
