/* Common styles for both desktop and mobile */
.roi-page-header {
  text-align: center;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  margin-bottom: 1.25rem;
}

.roi-form {
  background-color: #f8f9fa;
  padding: 1.25rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  margin-right: 1.25rem;
  flex: 1;
}

.roi-result {
  background-color: #f8f9fa;
  padding: 1.25rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  flex: 2;
}

.roi-form-group {
  margin-bottom: 1rem;
}

.roi-header {
  font-size: 1.5rem;
  margin-bottom: 1.25rem;
}

.roi-result-text {
  margin-top: 1.25rem;
  padding: 0.625rem;
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

/* Media query for mobile layout */
@media (max-width: 767px) {
  .d-flex {
    flex-direction: column;
  }

  .roi-form {
    margin-right: 0;
    margin-bottom: 1.25rem;
  }
}
