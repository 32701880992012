.quiz {
    background-color: var(--white);
    height: calc(100vh - 66px); //92vh
    position: relative;

    .nav-buttons {
        padding-top: 1rem;

        .right {
            text-align: center;
            background-color: var(--primary);
            border-color: var(--primary);
            border-radius: 10px;
            font-size: 0.8rem;
            font-weight: 600;
            color: var(--primary-text);
            width: 100%;
        }

        .left {
            text-align: center;
            background-color: transparent;
            border-color: var(--light-gray);
            border-radius: 10px;
            font-size: 0.8rem;
            font-weight: 600;
            color: var(--primary-text);
            width: 100%;
        }

    }

    .nav-title {
        font-weight: 700;
        letter-spacing: 0.2em;
        margin-top: 7px;

        a {
            color: var(--primary);
            user-select: none;
        }
    }

    .progress {
        height: 0.5rem;
    }

    .progress-bar {
        background-color: var(--primary);
    }

    .question-container {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .back-div {
        padding-top: 10px;
        padding-left: 10px;
    }

    #back-action {
        color: var(--text-button);
        font-weight: 600;
    }
}
