@media (max-width: 425px) {
  .form-check-label {
    font-size: 0.5rem;
  }

  button.left.btn.btn-primary,
  button.right.btn.btn-primary {
    font-size: 0.75rem;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.form-check {
  padding-left: 0;

  .form-check-input {
    margin-left: 0rem;
    border-color: var(--lighter-gray);

  }

  .form-check-label {
    background-color: var(--lighter-gray-fill);
    border-color: var(--lighter-gray);
    border-radius: 10px;
    border-style: solid;
    border-width: 1.5px;
    color: var(--gray-900);
    cursor: pointer;
    display: block;
    font-size: 0.8rem;
    padding-bottom: 0.6rem;
    padding-left: 0.5rem;
    padding-top: 0.6rem;
    transition: all 0.3s ease;

    &:hover {
      background-color: var(--lighter-gray-hover);
      border-color: var(--lighter-gray-hover);
    }

    &:focus{
      border-color: var(--primary);
      background-color: var(--lighter-gray); 
      outline: none;
    }

    &.checked { //testing
        border-color: var(--primary);
        background-color: var(--primary);
    }
  }
}

.card-text {
  color: var(--dark-blue);
  font-weight: 600;
  font-size: 0.8rem;
  text-align: justify;
}

.card {
  border: none;
  
  .card-title {
    color: var(--dark-blue);
    font-size: 26px;    
    line-height: 1.2;
    letter-spacing: -0.05em;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }

  h3 {
    font-weight: 800;
  }
}

.reference {
  font-size: smaller;
  font-weight: 500;
  text-align: end;
  display: block;
  font-style: italic;
  margin-top: 0.6rem;
}

