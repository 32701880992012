.login-container {
    border-radius: .5rem;
    box-shadow: 0 0 10px lightgrey;
    margin-bottom: 5vh;
    margin-top: 10vh;
    max-width: 80%;

    .form-display {
        min-height: 75vh;
    }

    .gradient-custom-left {
        /* fallback for old browsers */
        background: rgb(2, 0, 36);
        /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(2, 47, 65, 0.9332983193277311) 35%, rgba(3, 25, 38, 0.9304971988795518) 100%);
        /* Chrome 10-25, Safari 5.1-6 */
        background: -webkit-linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(2, 47, 65, 0.9332983193277311) 35%, rgba(3, 25, 38, 0.9304971988795518) 100%);

        align-items: center;
        display: flex;
        justify-content: center;
        user-select: none;

    }

    .gradient-custom-right {
        align-items: center;

    }

    .form-control {
        padding: 10px 10px 10px 15px;
    }

    span.input-group-text {
        border-left: none;
        background-color: transparent;
    }

    .btn-secondary {
        padding: 0px 5px;
        border-radius: 30px;
        color: var(--gray);
        background-color: var(--white);
        border: none;
    }

    .policy-disclaimer {
        font-size: 0.8rem;
        line-height: 1.3;
        text-align: justify;
        margin-bottom: 0.1rem;
    }

    .user-check-label {
        font-size: 0.8rem;
        padding-left: 0.3rem;
        cursor: pointer;
        text-align: justify;
        margin-bottom: 0.3rem;
        font-weight: 500;
        border: none;
    }

    .form-check-input {
        margin-top: 0.3rem;
        width: 20px;
        border-color: var(--primary);

        &:checked {
            border-color: var(--primary);
            background-color: var(--secondary);
        }
    }

    .invalid-feedback,
    .valid-feedback {
        font-size: 0.9rem;
    }

    h2 {
        margin-bottom: 2rem;
        text-align: center;
    }


    @media (max-width: 320px) {

        label.form-label,
        label.user-check-label,
        .policy-disclaimer {
            font-size: 0.5rem;
        }

        input.form-control,
        p,
        button.btn-primary {
            font-size: 0.8rem;
        }
    }

    @media (min-width: 320px) {
        .gradient-form {
            height: 100vh;
        }

        #riversafe-logo {
            height: auto;
            margin-top: 1rem;
            width: 5rem;
        }

        #riversafe-banner {
            height: auto;
            margin-bottom: 1rem;
            margin-top: 0.5rem;
            width: 5rem;
        }
    }


    @media (min-width: 1024px) {
        .gradient-custom-left {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
        }

        .gradient-custom-right {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
        }

        #riversafe-logo {
            width: 15rem;
            height: auto;
        }

        #riversafe-banner {
            width: 15rem;
            height: auto;
            margin-top: 2rem;
        }
    }

    .divider:after,
    .divider:before {
        content: "";
        flex: 1;
        height: 1px;
        background: #eee;
    }

}
