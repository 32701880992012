:root {
    --info: #FFFF00;
    --dark: black;
    --gray: #696F79; //button fill
    --light-gray: #D3D3D3;
    --lighter-gray-hover: #E0E4EA; 
    --lighter-gray: #EAECF0; //button stroke
    --lighter-gray-fill: #F3F5F8; //button fill
    --gray-900: #101828; // figma options text
    --background-gray: rgba(0, 0, 0, 0.4);
    --danger: #DC3545;
    --primary: #F9B000; //main button figma
    --primary-text: #222222; //charchoal figma
    --light-primary: #FEF1D9;
    --light-primary-background: #FDD1A7 ;
    --dark-primary: #EE7504;
    --dark-blue: #021926; //dark blue figma
    --secondary: #282C34;
    --text-button: #0A82B2;
    --white: #FFFFFF;
    --nav-bar: #212529;
}

@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons";
@import "@fontsource/montserrat";

body {
  font-family: 'Montserrat', sans-serif;
}

.navbar-brand {
    padding-top: 2px;
    padding-bottom: 2px
}

form {

    input.form-control,
    span.input-group-text {
        border-color: var(--dark-primary);
        padding: 8px 10px 8px 15px;
        border-radius: 20px;
        font-size: 1.1rem;

        &:focus {
            border-color: var(--background-gray);
            box-shadow: 1px 1px 2px var(--secondary) inset, 0 0 4px var(--secondary);
        }
    }

    input.form-check-input {
        &:focus {
            box-shadow: none;
        }

        &:checked[type=radio] {
            background-color: var(--gray-900);
            background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%272%27 fill=%27%101828%27/%3e%3c/svg%3e");
        }
    }

    label.form-label {
        font-size: 1.1rem;
        color: var(--gray);
        font-weight: 500;
        margin-bottom: 0.3em;
    }
}

.toast {
    &.bg-primary {
        --bs-bg-opacity: 0.5
    }
}

button.btn-primary,
.btn:first-child {
    border-radius: 16px;
    box-shadow: 2px 2px 2px 0px rgba($color: #000000, $alpha: 0.2);
    color: var(--dark-blue);
    background-color: var(--primary);
    border-color: var(--primary);

    &:active,
    &:target {
        box-shadow: 1px 1px 1px 0px rgba($color: #000000, $alpha: 0.2);
        background-color: var(--secondary);
        border-color: var(--secondary);
    }

    &:hover {
        box-shadow: 1px 1px 1px 0px rgba($color: #000000, $alpha: 0.2);
        background-color: var(--dark-primary);
        border-color: var(--dark-primary);
        opacity: 0.8;
    }

    &:disabled {
        box-shadow: 1px 1px 1px 0px rgba($color: #000000, $alpha: 0.2);
        background-color: var(--gray);
        border-color: var(--gray);
    }
}

a {
    text-decoration: none;
}

.card-img {
    width: 7rem;
    right: 0;
    position: absolute;
    margin-top: -2rem;
}

.modal {
    text-align: justify;
}

img {
    pointer-events: none;
}
